@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

.homepageimage {
  width: 100%;
  z-index: -1;
  position: absolute;
  top: 0;
  height: 50rem;
  background-size: cover;
  background-color: #868686;
  background-position: center;
}

.workimage {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 30rem;
  z-index: -1;
  position: absolute;
  background-position: center;
  background-size: cover;
}

.gradient {
  background-color: rgba(0, 0, 0, 0.3);
}

.header-container {
  height: 50rem;
  display: flex !important;
  justify-content: center;
}

.header-title {
  font-size: 3rem;
}

@media only screen and (max-width: 425px) {
  .homepageimage {
    width: 100%;
    z-index: -1;
    position: absolute;
    /* background-image: url("../assets/banner-principal.png"); */
    background-size: cover;
    background-color: #868686;
    background-position: center;
    background-repeat: no-repeat;
  }
  .header-container {
    height: 23rem;
    display: flex;
    justify-content: center;
  }
  .header-title {
    font-size: 1.5rem !important;
  }
  .text-list-footer p span {
    font-size: 12px;
  }
}

@media only screen and (max-width: 768px) {
  .menum {
    top: 0;
    position: absolute;
    padding-top: 4rem;
    font-size: 40px;
    font-weight: bold;
    /* background-image: url("../assets/banner-menu.png"); */
    background-color: black;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  .header .ui.container {
    margin: 0rem;
  }
  .homepageimage {
    width: 100%;
    height: 30.5rem;
    z-index: -1;
    position: absolute;
    /* background-image: url("../assets/banner-principal.png"); */
    background-size: cover;
    background-color: #868686;
    background-position: center;
    background-repeat: no-repeat;
  }
  .header-container {
    height: 24rem;
    display: flex !important;
    justify-content: center;
  }
  .header-title {
    font-size: 2rem;
  }
}

.ui.secondary.pointing.menu .active.item {
  border-color: #79bee9 !important;
  color: #79bee9 !important;
}

.animatedmenu {
  animation: moveInBottom 0.5s ease-out 0.1s;
  animation-fill-mode: backwards;
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.ui.attached.segment {
  border: 0 !important;
}

h2 {
  margin: 10;
}

.slick-dots li button:before {
  color: white !important;
  background-color: transparent !important;
}

.slick-dots li.slick-active button:before {
  color: white !important;
  font-weight: bold !important;
}

.input input::placeholder {
  color: gray !important;
  opacity: 1 !important;
}

.input input:focus::placeholder {
  color: gray !important;
  opacity: 0.5 !important;
}

textarea::placeholder {
  color: gray !important;
  opacity: 1 !important;
}

textarea:focus::placeholder {
  color: gray !important;
  opacity: 0.5 !important;
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
  color: gray !important;
  opacity: 1 !important;
}

.ui.accordion:not(.styled) .accordion .title ~ .content:not(.ui),
.ui.accordion:not(.styled) .title ~ .content:not(.ui) {
  padding: 0 !important;
}

.DButton:hover {
  background-color: #5aa7d1 !important;
  color: white !important;
}
